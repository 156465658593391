/*Icon colors:*/
$green-icon-color: #1ec97a;

/*Button colors:*/
$black-button-color: #000;
$black-button-disabled-color: #bcbcbc;
$black-button-text-color: #fff;
$black-button-disabled-text-color: #585858;
$grey-button-color: #dbdbdb;
$grey-button-text-color: #585858;

/*Divider colors:*/
$light-divider-color: #dbdbdb;
$black-divider-color: #000;

/*Text colors:*/
$grey-instruction-color: #585858;
$black-text-color: #000;

/*Search bar colours*/
$search-bar-background: #f4f4f4;
$search-bar-icon: #585858;

/*Tab group colours*/
$tab-group-border-color: #dbdbdb;