// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;

// Plus imports for other components in your app.
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;1,400;1,500&family=Nunito+Sans:ital,wght@0,400;0,600;0,700;1,400&display=swap');

@font-face {
  font-family: 'Material Symbols Outlined';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/symbols.woff2') format('woff2');
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  src: url('./assets/Montserrat-Light.ttf') format('truetype');
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  src: url('./assets/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  src: url('./assets/Montserrat-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  src: url('./assets/Montserrat-Bold.ttf') format('truetype');
}

$md-primary: (
  50 : #e5e6e7,
  100 : #bec2c3,
  200 : #93999c,
  300 : #677074,
  400 : #475156,
  500 : #263238,
  600 : #222d32,
  700 : #1c262b,
  800 : #171f24,
  900 : #0d1317,
  A100 : #5bb9ff,
  A200 : #28a3ff,
  A400 : #008bf4,
  A700 : #007dda,
  contrast: (
    50 : #e5e6e7,
    100 : #e5e6e7,
    200 : #e5e6e7,
    300 : #e5e6e7,
    400 : #e5e6e7,
    500 : #e5e6e7,
    600 : #e5e6e7,
    700 : #e5e6e7,
    800 : #e5e6e7,
    900 : #e5e6e7,
    a100 : #e5e6e7,
    a200 : #e5e6e7,
    a400 : #e5e6e7,
    a700 : #e5e6e7,
  )
);
$md-accent: (
  50 : #ebf3ed,
  100 : #cee2d1,
  200 : #aeceb3,
  300 : #8dba95,
  400 : #74ac7e,
  500 : #5c9d67,
  600 : #54955f,
  700 : #4a8b54,
  800 : #41814a,
  900 : #306f39,
  a100 : #baffc3,
  a200 : #87ff96,
  a400 : #54ff6a,
  a700 : #3bff54,
  contrast: (
    50 : #000,
    100 : #000,
    200 : #000,
    300 : #000,
    400 : #000,
    500 : #000,
    600 : #fff,
    700 : #fff,
    800 : #fff,
    900 : #fff,
    a100 : #000,
    a200 : #000,
    a400 : #000,
    a700 : #000,
  )
);
$md-secondary: (
  50 : #e0e0e0,
  100 : #b3b3b3,
  200 : #808080,
  300 : #4d4d4d,
  400 : #262626,
  500 : #000,
  600 : #000,
  700 : #000,
  800 : #000,
  900 : #000,
  a100 : #a6a6a6,
  a200 : #8c8c8c,
  a400 : #737373,
  a700 : #666,
  contrast: (
    50 : #000,
    100 : #000,
    200 : #000,
    300 : #fff,
    400 : #fff,
    500 : #fff,
    600 : #fff,
    700 : #fff,
    800 : #fff,
    900 : #fff,
    a100 : #000,
    a200 : #000,
    a400 : #fff,
    a700 : #fff,
  )
);
$md-warn: (
  50 : #ffeaec,
  100 : #ffcbcf,
  200 : #ffa8af,
  300 : #ff858f,
  400 : #ff6a77,
  500 : #ff505f,
  600 : #ff4957,
  700 : #ff404d,
  800 : #ff3743,
  900 : #ff2732,
  a100 : #fff,
  a200 : #fff,
  a400 : #ffd4d6,
  a700 : #ffbbbe,
  contrast: (
    50 : #000,
    100 : #000,
    200 : #000,
    300 : #000,
    400 : #000,
    500 : #000,
    600 : #000,
    700 : #fff,
    800 : #fff,
    900 : #fff,
    a100 : #000,
    a200 : #000,
    a400 : #000,
    a700 : #000,
  )
);
$akiru-primary: mat.m2-define-palette($md-primary);
$akiru-accent: mat.m2-define-palette($md-accent, a200, a100, a400);
$akiru-warn: mat.m2-define-palette($md-warn);
$typography: mat.m2-define-typography-config(
  $font-family: 'Nunito Sans',
  $headline-1: mat.m2-define-typography-level(112px, 112px, 400, $letter-spacing: -0.05em),
  $headline-2: mat.m2-define-typography-level(56px, 56px, 400, $letter-spacing: -0.02em),
  $headline-3: mat.m2-define-typography-level(45px, 48px, 400, $letter-spacing: -0.005em),
  $headline-4: mat.m2-define-typography-level(34px, 40px, 400, 'Nunito Sans', normal),
  $headline-5: mat.m2-define-typography-level(45px, 48px, 500, 'EB Garamond', normal), // H1
  $headline-6: mat.m2-define-typography-level(32px, 25px, 500, 'EB Garamond', normal), // h2
  $subtitle-1: mat.m2-define-typography-level(24px, 31.2px, 700, 'Nunito Sans', normal), // h3
  $subtitle-2: mat.m2-define-typography-level(18px, 21px, 400, 'Nunito Sans', normal), // h4
  $body-1: mat.m2-define-typography-level(18px, 24px, 400, 'Nunito Sans', normal), // Bold P
  $body-2: mat.m2-define-typography-level(15px, 19px, 400, 'Nunito Sans', normal), // P
  $caption: mat.m2-define-typography-level(14px, 18px, 400, 'Nunito Sans', normal), // Caption
  $button: mat.m2-define-typography-level(14px, 22px, 400, 'Nunito Sans', normal)
);


$akiru-theme: mat.m2-define-light-theme((
  color: (
    primary: $akiru-primary,
    accent: $akiru-accent,
    warn: $akiru-warn,
  ),
  typography: $typography
));

@include mat.all-component-themes($akiru-theme);
@include mat.all-component-typographies($typography);

.akiru-dark-theme {
  $dark-theme: mat.m2-define-dark-theme((
    color: (
      primary: $akiru-primary,
      accent: $akiru-accent,
      warn: $akiru-warn
    ),
  ));

  @include mat.all-component-colors($dark-theme);
}

@include mat.core;

.material-symbols-outlined {
  direction: ltr;
  display: inline-block;
  font-family: 'Material Symbols Outlined', serif;
  font-feature-settings: 'liga';
  font-size: 40px;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
}

// We put all "common" styles available for whole app
@import "common";

html {
  height: 100%;
  margin: 0;
  width: 100%;
}

h1,
h2 {
  font-style: italic !important;
  font-weight: 500;
  line-height: 30px !important;
}

body {
  background-color: #fff;
  margin: 0;
  height: 100%;
  width: 100%;
}

/**
All icons must me centred instead of appearing a bit to the left in their "boxes".
All buttons have 0 border.
 */
button {
  border: 0;

  .mat-icon {
    margin: 0 !important;
  }
}

// All dialogs should have a minimum border-radius. It's a design feature.
// We keep it here in general style to maintain consistency in all dialogs.
mat-dialog-container {
  --mdc-dialog-container-shape: 1px;
}

// Remove the background color applied on autocomplete in inputs
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px #fff inset;
}

// Change placeholder text color in input fields and textarea, can't find the correct mat variable
input::placeholder,
textarea::placeholder {
  color: #c4c4c4;
}

//TODO move this to the bankid library and change to mat-flat-button
jhc-bankid {
  button {
    width: 100% !important;
    box-shadow: none !important;
    border: 0 !important;
    border-radius: 0 !important;
    background-color: #000 !important;
    font-weight: bold !important;
  }
}
