@use 'colors' as colors;

@mixin foa-button {
  // Dimensions
  --mdc-filled-button-container-height: 40px;
  // Background and text colours
  --mdc-filled-button-container-color: #{colors.$black-button-color};
  --mdc-filled-button-label-text-color: #{colors.$black-button-text-color};
  // Background and text colours when disabled
  --mdc-filled-button-disabled-container-color: #{colors.$black-button-disabled-color};
  --mdc-filled-button-disabled-label-text-color: #{colors.$black-button-disabled-text-color};
  // Border radius for mat-buttons
  --mdc-filled-button-container-shape: 0;
  // Text styles
  --mdc-filled-button-label-text-weight: 600;
  --mdc-filled-button-label-text-size: 15px;
  line-height: 18px;
}

.foa-button {
  @include foa-button;
}

.foa-search-bar {
  width: 100%;
  background-color: colors.$search-bar-background;
  --mat-form-field-container-height: 34px;
  --mat-form-field-container-vertical-padding: 5px;
  --mdc-outlined-text-field-outline-color: transparent;
  --mdc-outlined-text-field-hover-outline-color: transparent;
  --mdc-outlined-text-field-focus-outline-color: transparent;

  mat-icon {
    color: colors.$search-bar-icon;
  }

  input {
    font-size: 15px !important;
    font-weight: bold !important;
    --mdc-outlined-text-field-input-text-color: #{colors.$search-bar-icon};
  }
}

.foa-tab-group {
  mat-tab-header {
    --mdc-secondary-navigation-tab-container-height: 33px !important;
    --mdc-tab-indicator-active-indicator-color: #{colors.$tab-group-border-color};
    --mat-tab-header-active-hover-indicator-color: #{colors.$tab-group-border-color};
    --mat-tab-header-active-focus-indicator-color: #{colors.$tab-group-border-color};
    --mdc-tab-indicator-active-indicator-height: 1px;
  }

  .mat-ripple * {
    width: 100%;
  }

  .foa-tab-holder {
    height: 100%;
    width: 100%;
  }
}
